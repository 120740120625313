export const permissionData = [
  {
    key: "Admin",
    title: "Admin User",
    tooltip_text:
      "Admin User has permission to manage all functions within the platform. The Admin User is the only user type with permission to manage subscriptions. Admin User should only be assigned to those who have the authority to purchase additional users and renew the annual subscription.",
    description: [
      {
        name: "Add/Edit/Delete Campaigns",
        value: 0,
      },
      {
        name: "Add/Edit/Delete Users",
        value: 3,
      },
      {
        name: "Subscription Management",
        value: 2,
      },
    ],
  },
  {
    key: "Standard",
    title: "Standard User",
    tooltip_text:
      "Standard User is the main user type within the platform. The Standard User has permission to manage campaigns and adverts. The Standard User can be given permission by an Admin User or another Standard User, to manage other users, as required.",
    description: [
      { name: "Add/Edit/Delete Campaigns", value: 0 },
      { name: "Add/Edit/Delete Users", value: 3 },
    ],
  },
  {
    key: "Association",
    title: "Association User",
    tooltip_text:
      "Association User is assigned to external partners and clients. The Association User has permission to view candidates and to approve candidates for specific adverts. The Association User does NOT have full access to the platform and only has permission see the campaigns and adverts assigned to them by either Standard Users or Admin Users.",
    description: [
      { name: "Accept/Reject Candidate", value: 5 },
      {
        name: "Leave notes/comments",
        value: 4,
      },
    ],
  },
];
export const permissionDataUser = [
  {
    title: "Admin User",
    tooltip_text:
      "Admin User has permission to manage all functions within the platform. The Admin User is the only user type with permission to manage subscriptions. Admin User should only be assigned to those who have the authority to purchase additional users and renew the annual subscription.",
    description: [
      { name: "Add/Edit/Delete Campaigns", value: 0 },
      { name: "Add/Edit/Delete Users", value: 3 },
      { name: "Subscription Management", value: 2 },
    ],
  },
  {
    title: "Standard User",
    tooltip_text:
      "Standard User is the main user type within the platform. The Standard User has permission to manage campaigns and adverts. The Standard User can be given permission by an Admin User or another Standard User, to manage other users, as required.",
    description: [
      { name: "Add/Edit/Delete Campaigns", value: 0 },
      { name: "Add/Edit/Delete Users", value: 3 },
    ],
  },
];
export const permissionDataUserAssociate = [
  {
    key: "Association",
    title: "Association User",
    tooltip_text:
      "Association User is assigned to external partners and clients. The Association User has permission to view candidates and to approve candidates for specific adverts. The Association User does NOT have full access to the platform and only has permission see the campaigns and adverts assigned to them by either Standard Users or Admin Users.",
    description: [
      { name: "Accept/Reject Candidate", value: 5 },
      {
        name: "Leave notes/comments",
        value: 4,
      },
    ],
  },
];
export const userPermissionWithNumber = [
  {
    name: "Add/Edit/Delete Campaigns",
    value: 0,
  },
  {
    name: "Subscription Management",
    value: 2,
  },
  {
    name: "Add/Edit/Delete Users",
    value: 3,
  },
  {
    name: "Leave notes/comments",
    value: 4,
  },
  {
    name: "Accept/Reject Candidate",
    value: 5,
  },
];
export const AddCampaignMockData = [
  {
    title: "WestconnextA",
    id: "1",
  },
  {
    title: "WestconnextB",
    id: "2",
  },
  {
    title: "WestconnextC",
    id: "3",
  },
  {
    title: "WestconnextD",
    id: "4",
  },
  {
    title: "WestconnextE",
    id: "5",
  },
  {
    title: "WestconnextF",
    id: "6",
  },
  {
    title: "WestconnextG",
    id: "7",
  },
  {
    title: "WestconnextF",
    id: "8",
  },
  {
    title: "WestconnextF",
    id: "9",
  },
  {
    title: "WestconnextF",
    id: "10",
  },
  {
    title: "WestconnextF",
    id: "11",
  },
];
export const CampaignMockData = [
  {
    campaignName: "WestconnextX",
    advertName: "Electrician",
    start_date: "23 JUL 2023",
    end_date: "23 OCT 2023",
    status: "Active",
  },
  {
    campaignName: "WestconnextX",
    advertName: "Electrician",
    start_date: "23 JUL 2023",
    end_date: "23 OCT 2023",
    status: "Active",
  },
  {
    campaignName: "WestconnextX",
    advertName: "Electrician",
    start_date: "23 JUL 2023",
    end_date: "23 OCT 2023",
    status: "Archived",
  },
  {
    campaignName: "WestconnextX",
    advertName: "Electrician",
    start_date: "23 JUL 2023",
    end_date: "23 OCT 2023",
    status: "Archived",
  },
  {
    campaignName: "WestconnextX",
    advertName: "Electrician",
    start_date: "23 JUL 2023",
    end_date: "23 OCT 2023",
    status: "Archived",
  },
  {
    campaignName: "WestconnextX",
    advertName: "Electrician",
    start_date: "23 JUL 2023",
    end_date: "23 OCT 2023",
    status: "Archived",
  },
  {
    campaignName: "WestconnextX",
    advertName: "Electrician",
    start_date: "23 JUL 2023",
    end_date: "23 OCT 2023",
    status: "Archived",
  },
];
export const PickListMockData = [
  {
    key: "QUALIFICATION",
    title: "Qualification",
    mode: "multiple",
  },
  {
    key: "INDUCTION",
    title: "Inductions",
    mode: "multiple", //doubt
  },

  {
    key: "CITIZENSHIP",
    title: "Citizenship",
    mode: "single",
  },
  {
    key: "VISATYPENUMBER",
    title: "Visa Type Number",
    mode: "single",
  },
  {
    key: "POLICECLEARANCE",
    title: "Police Clearance",
    mode: "single",
  },
  {
    key: " DISCIPLINE",
    title: "Discipline",
    mode: "single",
  },
  {
    key: "ETHNICITY",
    title: "Ethnicity",
    mode: "single",
  },
  {
    key: "GENDER",
    title: "Gender",
    mode: "single",
  },
  {
    key: "STATE",
    title: "State",
    mode: "single",
  },
  {
    key: "COUNTRY",
    title: "Country",
    mode: "single",
  },
  {
    key: "LICENCETYPE",
    title: "Licence Type",
    mode: "multiple",
  },
  {
    key: "LICENCE_CONDITION",
    title: "License Condition",
    mode: "multiple", //doubt
  },
  {
    key: "MEDICALS",
    title: "Medical",
    mode: "single",
  },
  {
    key: "VISA",
    title: "VISA",
    mode: "single", //doubt
  },
  {
    key: "ID_TYPE",
    title: "ID Type",
    mode: "single", //doubt
  },
  {
    key: "FUND_NAME",
    title: "Fund Name",
    mode: "single", //doubt
  },
  {
    key: "FUND_USI_ABN",
    title: "Fund USI_ABN",
    mode: "single",
  },
  {
    key: "CERTIFYING_AUTHORITY",
    title: "Certifying Authority",
    mode: "single",
  },
  {
    key: "MEDICAL_ASSESSMENT",
    title: "Medical Assessment",
    mode: "multiple",
  },
  {
    key: "SKILL",
    title: "Skill",
    mode: "multiple",
  },
  {
    key: "ROSTER",
    title: "Roaster",
    mode: "single", //doubt
  },
  {
    key: "EMPLOYMENT_TYPE",
    title: "Employment Type",
    mode: "single", //doubt
  },
  {
    key: "JOB_DURATION",
    title: "Job Duration",
    mode: "single", //doubt
  },
  {
    key: "CONTACT_US",
    title: "Contact Us",
    mode: "single", //single
  },
  {
    key: "HIDE_ACCOUNT",
    title: "Hide Account",
    mode: "single", //doubt
  },
  {
    key: "DELETE_ACCOUNT",
    title: "Delte Account",
    mode: "single", //doubt
  },
  {
    Key: "VOC",
    title: "VOC",
    mode: "single",
  },
  {
    key: "JOBTITLE",
    title: "Job Title",
    mode: "single",
  },
  {
    key: "INDUSTRY",
    title: "Industry",
    mode: "mutltiple",
  },
  {
    key: "LICENCE_CLASS",
    title: "Licence Class",
    mode: "mutltiple",
  },
  {
    key: "SUBJECT",
    title: "Subject",
    mode: "single", //doubt
  },
];
export const pickList_phone = [
  {
    key: "QUALIFICATION",
    title: "Qualification",
    mode: "multiple",
  },
  {
    key: "INDUCTION",
    title: "Inductions",
    mode: "multiple", //doubt
  },

  {
    key: "CITIZENSHIP",
    title: "Citizenship",
    mode: "",
  },
  {
    key: "VISATYPENUMBER",
    title: "Visa Type Number",
    mode: "single",
  },
  {
    key: "POLICECLEARANCE",
    title: "Police Clearance",
    mode: "",
  },

  {
    key: "MEDICAL_ASSESSMENT",
    title: "Medical Assessment",
    mode: "multiple",
  },
  {
    key: "SKILL",
    title: "Skills",
    mode: "multiple",
  },
  {
    key: "ID_DOCUMENT",
    title: "ID Document",
    mode: "",
  },
];

export const jobAdvertsMockData = [
  {
    name: "Company A PVT. LTD",
    campaignName: "WestconnectX",
    JobAdvert: "Electrician",
    startDate: "23 July 2023",
    endDate: "30 July 2023",
  },
  {
    name: "Company A PVT. LTD",
    campaignName: "WestconnectX",
    JobAdvert: "Plumber",
    startDate: "23 July 2023",
    endDate: "30 July 2023",
  },
  {
    name: "Company A PVT. LTD",
    campaignName: "WestconnectX",
    JobAdvert: "Civil Engineer",
    startDate: "23 July 2023",
    endDate: "30 July 2023",
  },
  {
    name: "Company A PVT. LTD",
    campaignName: "WestconnectX",
    JobAdvert: "Civil Engineer",
    startDate: "23 July 2023",
    endDate: "30 July 2023",
  },
  {
    name: "Company A PVT. LTD",
    campaignName: "WestconnectX",
    JobAdvert: "Civil Engineer",
    startDate: "23 July 2023",
    endDate: "30 July 2023",
  },
  {
    name: "Company A PVT. LTD",
    campaignName: "WestconnectX",
    JobAdvert: "Civil Engineer",
    startDate: "23 July 2023",
    endDate: "30 July 2023",
  },
  {
    name: "Company A PVT. LTD",
    campaignName: "WestconnectX",
    JobAdvert: "Civil Engineer",
    startDate: "23 July 2023",
    endDate: "30 July 2023",
  },
];
export const numberWithCommas = (number) => {
  if (number) {
    number = number?.toString();
  }
  if (number?.length > 0) {
    var parts = number?.toString()?.split(".");
    if (parts?.length > 0) {
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
    } else {
      return number;
    }
  } else {
    return number;
  }
};
export function downloadPDF(pdf, name) {
  try {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = `${name}.pdf`;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  } catch (error) {
    // message.error("Something went wrong. Please try again.");
  }
}

export function downloadCsv(data, name) {
  try {
    const blob = new Blob([data], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "export.csv"); // Set the file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    // message.error("Something went wrong. Please try again.");
  }
}
